import React, { useContext, useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Grid, TextField } from '@material-ui/core'
import { WrapperSection } from '../../wrappers'
import { colors, s, alpha, q, globals } from '../../style'
import { Link } from 'gatsby'
import { ButtonMain } from '../../buttons'
import { sTextInput } from '../../forms/FormItems/FormInputs'
import { T } from '../../typo'
import { StateContext, initialState } from '../../../context'

const theme = 'light'

const Newsletter = () => {
  const state = useContext(StateContext)
  const theme = state?.theme ? state.theme : initialState.theme
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [agreement, setAgreement] = useState(false)

  const _handleSubmit = (e) => {
    if (e) e.preventDefault()

    // console.log('submit', this.state)
    if (agreement)
      addToMailchimp(email)
        .then(({ msg, result }) => {
          // console.log('msg', `${result}: ${msg}`)
          setMessage(
            'Subskrybujesz! Twój adres został poprawnie dodany do listy subskrybentów newslettera.'
          )
          setAgreement(false)
          setEmail('')
        })
        .catch((err) => {
          // console.log('err', err)
          setMessage(
            'Niepowodzenie! Twoja wiadmość nie została wysłana. Email niepoprawny lub istnieje w naszej bazie.'
          )
        })
  }

  return (
    <WrapperSection
      id="newsletter"
      sSection
      css={{ [s.sm_down]: { paddingTop: 0 } }}>
      <div css={[sSection, { backgroundColor: colors[theme].container }]}>
        <Grid container onClick={() => message && setMessage('')}>
          {message ? (
            <>
              <Grid item xs={12}>
                <T d={32} m={24} bold mb={0.5}>
                  {message.split('!')[0]}
                </T>
                <T mb={2}>{message.split('!')[1]}</T>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <T d={32} m={24} bold mb={0.5}>
                  Bądź na bieżąco z aktualnościami marketingowymi!
                </T>
                <T mb={2}>
                  Zapisz się do Newslettera i odbieraj informacje raz w
                  miesiącu.
                </T>
              </Grid>
            </>
          )}
          {!message && (
            <>
              <Grid item xs={12}>
                <form onSubmit={_handleSubmit}>
                  <TextField
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    css={sTextInput(theme)}
                    variant="filled"
                    label="Email"
                    fullWidth
                  />
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                css={[
                  {
                    textAlign: 'left',
                    display: 'flex',
                    padding: '2rem 0',
                    [s.sm_down]: { padding: '2rem 0' },
                    '.form_agreement_check': {
                      cursor: 'pointer',
                      flexShrink: 0,
                      transition:
                        'box-shadow 0.25s ease-out, opacity 0.25s ease-out',
                      ...q.center,
                      width: 20,
                      height: 20,
                      boxShadow: `0px 0px 0px 2px ${colors[theme].text}`,
                      opacity: 0.3,
                      marginRight: '1rem',
                      marginTop: '0.5rem',
                      borderRadius: 10,
                    },
                    '.form_agreement_check_inside': {
                      transition:
                        'transform 0.25s ease-in-out, height 0.25s ease-out',
                      width: 14,
                      height: 14,
                      borderRadius: 8,
                      backgroundColor:
                        theme === 'light'
                          ? colors[theme].main
                          : colors[theme].text,
                      transform: 'scale(0)',
                    },
                  },
                  agreement && {
                    '.form_agreement_check': {
                      boxShadow:
                        theme === 'light'
                          ? `0px 0px 0px 2px ${colors[theme].main}`
                          : `0px 0px 0px 2px ${colors[theme].text}`,
                      opacity: 1,
                    },
                    '.form_agreement_check_inside': {
                      transform: 'scale(1)',
                    },
                  },
                ]}>
                <div
                  className="form_agreement_check"
                  onClick={() => setAgreement(!agreement)}
                  onKeyPress={() => setAgreement(!agreement)}
                  role="button"
                  tabIndex={0}>
                  <div className="form_agreement_check_inside" />
                </div>
                <span
                  css={{
                    fontSize: 12,
                    opacity: 0.6,
                    color: colors[theme].text,
                  }}>
                  Wyrażam zgodę na przesyłanie przez Administratora informacji
                  handlowych, w tym newslettera na podany przeze mnie adres
                  poczty elektronicznej, zgodnie z ustawą o świadczeniu usług
                  drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U. z 2016
                  poz. 1030 ze zm.) Wszelkie informacje handlowe są całkowicie
                  bezpłatne. Powyższa zgoda jest dobrowolna i może być odwołana
                  w dowolnym momencie.
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
                onClick={() => _handleSubmit()}>
                <ButtonMain theme={theme} extraButtonBase={{ width: '100%' }}>
                  {agreement ? 'Zapisz mnie' : 'Zaznacz zgodę'}
                </ButtonMain>
              </Grid>
              <Grid
                item
                xs={12}
                css={{
                  textAlign: 'left',
                  padding: '2rem 0 0',
                }}>
                <span
                  css={{
                    fontSize: 12,
                    opacity: 0.6,
                    color: colors[theme].text,
                  }}>
                  Sprawdź naszą &nbsp;
                  <Link
                    to="/pl/polityka-prywatnosci"
                    css={{ opacity: 1, textDecoration: 'underline' }}>
                    Politykę Prywatności
                  </Link>
                  i dowiedz się, w jaki sposób przetwarzamy dane. W każdej
                  chwili możesz przerwać subskrybcję newslettera za darmo.
                </span>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </WrapperSection>
  )
}

const newsletter = {
  form: {
    padding: '122px 64px 0px 64px',
    justifyContent: 'right',
    alignItems: 'right',
    [s.sm_down]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 24,
    },
    [s.xs]: { padding: 24 },
  },
  text: {
    padding: '64px',
    h1: {
      marginBottom: 32,
      fontSize: '2rem',
    },
    p: {
      opacity: 0.6,
      fontSize: '1rem',
      lineHeight: 1.62 + 'rem',
    },
    [s.sm_down]: { padding: 24 },
  },
}
const newsletterInput = {
  padding: '12px',
  width: 300,
  height: '100%',
  fontSize: '14px',
  borderWidth: '0px',
  borderColor: '#CCCCCC',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  borderStyle: 'solid',
  borderRadius: '32px',
  boxShadow: '0px 0px 5px rgba(66,66,66,.75)',
  ':focus': {
    outline: 'none',
  },
}
const newsletterButton = {
  zIndex: 999,
  backgroundColor: colors.brand,
  borderRadius: '32px',
  border: 'unset',
  display: 'inline-block',
  cursor: 'pointer',
  color: '#ffffff',
  fontSize: '17px',
  fontWeight: 700,
  padding: '16px 31px',
  transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
  ':hover': {
    transform: 'scale(0.9)',
  },

  ':active': { transition: '0.3s easy-in-out' },
}
const sSection = {
  borderRadius: 32,
  background: `#fff`,
  display: 'flex',
  flexDirection: 'row',
  color: colors.main,
  padding: globals.spacing.inside.desktop,
  [s.sm_down]: {
    padding: globals.spacing.inside.mobile,
  },
  [s.xs]: {
    flexDirection: 'column',
    borderRadius: 24,
  },
}

export default Newsletter
