import React from 'react'
import Newsletter from '../newsletter/newsletter'
const NewsLetter = ({ theme, data }) => {
  //   const data = useStaticQuery(graphql`
  //   {
  //     banner: allMarkdownRemark(
  //       filter: { fileAbsolutePath: { regex: "/content/junk/" } }
  //     ) {
  //       edges {
  //         node {
  //           frontmatter {
  //             language
  //             bannerCall {
  //               title
  //               subtitle
  //               phone
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // const d = data.banner.edges
  //   .filter(({ node }) =>
  //     language
  //       ? node.frontmatter.language === language
  //       : node.frontmatter.language === 'pl'
  //   )
  //   .map(({ node }) => node.frontmatter.bannerCall)[0]
  return <Newsletter />
}
export default NewsLetter
